<template>
  <div class="generateProject">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="序号" type="index"></el-table-column>
      <el-table-column prop="number" label="编号"></el-table-column>
      <el-table-column prop="count" label="建议项目数"></el-table-column>
      <el-table-column prop="time" label="生成日期"> </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleExamine(scope.row)"
            >查看</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleDownload(scope.row)"
            >打印</el-button
          >
        </template>
      </el-table-column>
    </el-table>
     <div class="footer" style="margin-top:40px;text-align:center">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
  </div>
</template>

<script>
import { request } from '../../../../../../network';
export default {
  name: 'Project',
  data () {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      pageSizes: [ 10, 20, 30, 40 ],
      fullscreenLoading: false
    };
  },
  mounted () {
    this.getProjectAdviseList(this.currentPage, this.pageSize);
  },
  methods: {
    handleExamine (v) {
      request({
        method: 'GET',
        url: '/pcp/org/getMatchDetail',
        params: {
          isPrint: '0',
          pushNo: v.number
        }
      }).then(res => {
        if (res.data.code === 200) {
          const params = {
            toggle: 'first',
            goods: res.data.data
          };
          this.loading = !this.loading;
          this.$emit('check', params);
        }
      })
        .catch(err => {
          console.log(err);
        });
    },
    handleDownload (v) {
      this.$router.push({ name: 'adviceTable', query: { pushNo: v.number } });
    },
    handleSizeChange (val) {
      this.getProjectAdviseList(this.currentPage, val);
    },
    handleCurrentChange (val) {
      this.getProjectAdviseList(val, this.pageSize);
    },
    getProjectAdviseList (pageNo, pageSisze) {
      request({
        method: 'POST',
        url: '/pcp/org/getProjectAdviseList',
        data: {
          pageNo: pageNo,
          pageSisze: pageSisze,
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.project;
            this.total = res.data.data.amount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="less" scoped>
</style>
